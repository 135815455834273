import { FC, useEffect, useCallback, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { HiDocumentDownload } from 'react-icons/hi';
import { Button, Spinner, Table } from 'flowbite-react';

import { useGlobal } from '../../hooks/useGlobal';
import { CardContainer } from '../../layout';
import { getStatistics } from '../../services';
import { canView } from '../../utils/permissions';

interface ITimePeriod {
  key: string;
  label: string;
}

interface ITableFooterTotal {
  bahisal: number;
  betkanyon: number;
  unibahis: number;
  winxbet: number;
  winxbetBr: number;
  betloto: number;
  kasino: number;
  total: number;
  contactCardCount: number;
}

interface IStatistic {
  date: string;
  totalMessage: number;
  messageCountBySiteId: { [key: number]: number };
  contactCardCount: number;
}

const timePeriods: ITimePeriod[] = [
  { key: 'thisWeek', label: 'Bu Hafta' },
  { key: 'lastWeek', label: 'Geçen Hafta' },
  { key: 'thisMonth', label: 'Bu Ay' },
  { key: 'lastMonth', label: 'Geçen Ay' },
];

const tableFooterTotalInit: ITableFooterTotal = {
  bahisal: 0,
  betkanyon: 0,
  unibahis: 0,
  winxbet: 0,
  winxbetBr: 0,
  betloto: 0,
  kasino: 0,
  total: 0,
  contactCardCount: 0,
};

export const StatisticsMain: FC = () => {
  const { user } = useGlobal();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('thisMonth');
  const [statistics, setStatistics] = useState<IStatistic[]>([]);
  const [tableFooterTotal, setTableFooterTotal] =
    useState<ITableFooterTotal>(tableFooterTotalInit);

  if (!canView(user, 'statistics')) {
    return <Navigate to="/" />;
  }

  const calculateTotals = (data: any) => {
    return data.reduce(
      (acc: ITableFooterTotal, item: IStatistic) => {
        acc.bahisal += item.messageCountBySiteId[3];
        acc.betkanyon += item.messageCountBySiteId[1];
        acc.unibahis += item.messageCountBySiteId[5];
        acc.winxbet += item.messageCountBySiteId[2];
        acc.winxbetBr += item.messageCountBySiteId[4];
        acc.betloto += item.messageCountBySiteId[7];
        acc.kasino += item.messageCountBySiteId[8];
        acc.total += item.totalMessage;
        acc.contactCardCount += item.contactCardCount;

        return acc;
      },
      {
        bahisal: 0,
        betkanyon: 0,
        unibahis: 0,
        winxbet: 0,
        winxbetBr: 0,
        betloto: 0,
        kasino: 0,
        total: 0,
        contactCardCount: 0,
      },
    );
  };

  // fetch statistics
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response: any = await getStatistics({
        period: selectedPeriod,
      });

      const tempTotals = calculateTotals(response.data);
      setTableFooterTotal(tempTotals);
      setStatistics(response.data);
    } catch (error) {
      console.error('fetchStatistics error:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedPeriod]);

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CardContainer title="İstatistikler">
      {/* date picker */}
      <div className="items-center flex justify-between">
        <div className="flex gap-4">
          <Button.Group>
            <Button color="dark">Siteler</Button>
            <Button color="gray" disabled>
              Kullanıcılar
            </Button>
          </Button.Group>
        </div>
        <div className="flex gap-4">
          <Button.Group>
            {timePeriods.map((item, i) => (
              <Button
                color={selectedPeriod === item.key ? 'dark' : 'light'}
                key={i}
                onClick={() => handlePeriodChange(item.key)}
              >
                {item.label}
              </Button>
            ))}
          </Button.Group>
          <Button
            color="gray"
            as={Link}
            to={`${
              process.env.NODE_ENV === 'development'
                ? process.env.REACT_APP_BASE_URL_DEV
                : process.env.REACT_APP_BASE_URL
            }/statistics/excel-export?period=${selectedPeriod}`}
          >
            <HiDocumentDownload />
          </Button>
        </div>
      </div>
      {/* date picker */}
      <div className="mt-4">
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Table striped hoverable>
            <Table.Head>
              <Table.HeadCell></Table.HeadCell>
              <Table.HeadCell>BA</Table.HeadCell>
              <Table.HeadCell>BK</Table.HeadCell>
              {/* <Table.HeadCell>Unibahis</Table.HeadCell> */}
              <Table.HeadCell>WX</Table.HeadCell>
              {/* <Table.HeadCell>WinxbetBr</Table.HeadCell> */}
              <Table.HeadCell>BL</Table.HeadCell>
              <Table.HeadCell>KA</Table.HeadCell>
              <Table.HeadCell>Mesaj</Table.HeadCell>
              <Table.HeadCell>Kişi Kartı</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {statistics.map((item: IStatistic, i: number) => {
                const {
                  date,
                  totalMessage,
                  messageCountBySiteId,
                  contactCardCount,
                } = item;

                return (
                  <Table.Row key={i}>
                    <Table.Cell>{date}</Table.Cell>
                    <Table.Cell>{messageCountBySiteId[3]}</Table.Cell>
                    <Table.Cell>{messageCountBySiteId[1]}</Table.Cell>
                    {/* <Table.Cell>{messageCountBySiteId[5]}</Table.Cell> */}
                    <Table.Cell>{messageCountBySiteId[2]}</Table.Cell>
                    {/* <Table.Cell>{messageCountBySiteId[4]}</Table.Cell> */}
                    <Table.Cell>{messageCountBySiteId[7]}</Table.Cell>
                    <Table.Cell>{messageCountBySiteId[8]}</Table.Cell>
                    <Table.Cell>{totalMessage}</Table.Cell>
                    <Table.Cell>{contactCardCount}</Table.Cell>
                  </Table.Row>
                );
              })}
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>{tableFooterTotal.bahisal}</Table.Cell>
                <Table.Cell>{tableFooterTotal.betkanyon}</Table.Cell>
                {/* <Table.Cell>{tableFooterTotal.unibahis}</Table.Cell> */}
                <Table.Cell>{tableFooterTotal.winxbet}</Table.Cell>
                {/* <Table.Cell>{tableFooterTotal.winxbetBr}</Table.Cell> */}
                <Table.Cell>{tableFooterTotal.betloto}</Table.Cell>
                <Table.Cell>{tableFooterTotal.kasino}</Table.Cell>
                <Table.Cell>{tableFooterTotal.total}</Table.Cell>
                <Table.Cell>{tableFooterTotal.contactCardCount}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </div>
    </CardContainer>
  );
};
