import React, { FC, useEffect, useState, useCallback } from 'react';
import {
  Spinner,
  Button,
  Label,
  TextInput,
  Select,
  Textarea,
} from 'flowbite-react';
import { useParams } from 'react-router-dom';
import { HiPlus, HiTrash } from 'react-icons/hi';
import { IMessageTemplate } from '../../types';
import { getMessageTemplateById, updateMessageTemplate } from '../../services';
import { useGlobal } from '../../hooks/useGlobal';
import { Msg } from '../../consts';
import { CardContainer } from '../../layout';

const messageLanguages = [
  {
    key: 'tr',
    label: 'TR',
  },
  {
    key: 'br',
    label: 'BR',
  },
];

interface IMessageRow {
  text: string;
  button: any;
  onChange: (e: any) => void;
}

const MessageRow: FC<IMessageRow> = ({
  text,
  button,
  onChange,
}: IMessageRow) => {
  return (
    <div className="flex gap-4">
      <div className="w-full">
        <Textarea value={text} onChange={onChange} />
      </div>
      <div>{button}</div>
    </div>
  );
};

export const MessageTemplateDetail: FC = () => {
  const { showAlert } = useGlobal();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [data, setData] = useState<IMessageTemplate>();

  const fetchData = useCallback(async () => {
    if (!id) return;

    try {
      const response: any = await getMessageTemplateById(id);

      setData(response.data);
    } catch (error) {
      console.error('getMessageTemplates error:', error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const handleAddMessage = () => {
    const newMessage = { id: '', text: '', date: new Date() };
    setData((prevData: any) => ({
      ...prevData,
      messages: [...prevData.messages, newMessage],
    }));
  };

  const handleDeleteMessage = (index: number) => {
    setData((prevData: any) => ({
      ...prevData,
      messages: prevData.messages.filter(
        (_: any, msgIndex: number) => msgIndex !== index,
      ),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!data?._id) return;

    setFormLoading(true);

    try {
      await updateMessageTemplate(data?._id, data);

      showAlert({
        text: Msg.Success,
        icon: 'success',
      });
    } catch (error) {
      showAlert({
        text: Msg.Error,
        icon: 'error',
      });

      console.error('updateMessageTemplate error:', error);
    } finally {
      setFormLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CardContainer title="Mesaj Taslak Düzenle">
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="grid w-full grid-cols-3 gap-4 mb-4">
            <div>
              <div className="block">
                <Label htmlFor="name" value="Başlık" />
              </div>
              <TextInput
                id="name"
                name="name"
                type="text"
                value={data?.name || ''}
                required
                onChange={(e) => {
                  setData((p: any) => ({
                    ...p,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
            <div>
              <div className="block">
                <Label htmlFor="templateCountry" value="Dil" />
              </div>
              <Select
                id="templateCountry"
                required
                onChange={(e) => {
                  setData((p: any) => ({
                    ...p,
                    country: e.target.value,
                  }));
                }}
              >
                <option>Seçiniz</option>
                {messageLanguages.map((item: any) => (
                  <option
                    key={item.key}
                    value={item.key}
                    selected={item.key === data?.country}
                  >
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <div className="block">
                <Label htmlFor="templateStatus" value="Durum" />
              </div>
              <Select
                id="templateCountry"
                required
                onChange={(e) => {
                  setData((p: any) => ({
                    ...p,
                    status: e.target.value === 'true',
                  }));
                }}
              >
                <option value="true" selected={data?.status === true}>
                  Aktif
                </option>
                <option value="false" selected={data?.status === false}>
                  Pasif
                </option>
              </Select>
            </div>
          </div>
          <div className="mb-4 flex gap-4 flex-col	">
            {data?.messages.map((message: any, index: number) => {
              return (
                <MessageRow
                  key={index}
                  text={message.text}
                  onChange={(e) => {
                    const newMessages = [...data.messages];
                    newMessages[index].text = e.target.value;
                    setData({ ...data, messages: newMessages });
                  }}
                  button={
                    index === 0 ? (
                      <Button
                        size="sm"
                        color="light"
                        onClick={handleAddMessage}
                      >
                        <HiPlus />
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        color="light"
                        onClick={() => handleDeleteMessage(index)}
                      >
                        <HiTrash />
                      </Button>
                    )
                  }
                />
              );
            })}
          </div>
          <div className="flex justify-between">
            <Button
              type="submit"
              color="blue"
              isProcessing={formLoading}
              disabled={formLoading}
            >
              Kaydet
            </Button>
          </div>
        </form>
      )}
    </CardContainer>
  );
};
